import React, { PropsWithChildren } from "react"
import Link from "next/link"

import {
	Layout,
	Newsletter,
	ContactForm,
	ContactFormValues,
	NewsletterFormValues,
} from "@bratislava/susedia-components"

import { map } from "lodash"
import { notification } from "antd"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { form } from "../api"

import { getSelectedMenu } from "../redux/menu"
import { FORM_TYPE } from "../enums/form"
import { useRouter } from "next/router"
import { getThemesSelect } from "../redux/themes"
import { Article } from "../types/data"

const { TreesLayout, Container } = Layout

const MainLogo = require("../assets/images/logos/main-logo.svg")
const HeaderLogo = require("../assets/images/logos/header-logo.svg")
const Logo = require("../assets/images/logos/logo-mesto-bratislava.svg")

const SocialsImage = require("../assets/images/backgrounds/socials-background.png")
const NewsletterImage = require("../assets/images/backgrounds/newsletter-background.png")
const ContactFormImage = require("../assets/images/backgrounds/contact-form-background.png")

type Props = PropsWithChildren<{
	headerLogo?: string
	menuType?: "white" | "transparent"
	showMenu?: boolean
	hideNewsletter?: boolean
}>

const args = (themes: Article[]) => ({
	logo: {
		url: MainLogo,
		alt: "Mesto Bratislava",
	},

	headerLogo: {
		url: HeaderLogo,
		alt: "Mesto Bratislava",
	},
	headerTitle: "Hlavné mesto Slovenskej republiky Bratislava",

	footerLogo: {
		url: Logo,
		alt: "Mesto Bratislava",
	},
	footerAddress:
		"Magistrát hlavného mesta SR Bratislavy<br/>Primaciálne námestie č. 1<br/>814 99 Bratislava",
	footerAbout: "Bratislavskí susedia",
	footerOther: "Projekty mesta",
	footerLinks: [
		{
			title: "Domov",
			path: "/",
		},
		{
			title: "Ako sa zapojiť",
			path: "/ako-sa-zapojit",
		},
		{
			title: "Blog",
			path: "/blog",
		},
		{
			title: "Zelená mapa",
			path: "/mapa",
		},
		{
			title: "Kontakt",
			path: "/kontakt",
		},
		{
			title: "Ochrana osobných údajov",
			path: "https://bratislava.sk/sk/ochrana-osobnych-udajov",
		},
	],
	footerProjects: [
		{
			title: "10 000 stromov",
			path: "https://10000stromov.sk",
		},
		{
			title: "Vyhlásenie o prístupnosti",
			path: "/files/public/vyhlasenie-o-pristupnosti-susedia.pdf",
		},
	],

	menuItems: [
		{
			key: "ABOUT",
			icon: "",
			title: "Domov",
			path: "/",
		},
		{
			key: "INFO",
			icon: "",
			title: "Ako sa zapojiť",
			path: "/ako-sa-zapojit",
		},
		{
			key: "TOPICS",
			icon: "",
			title: "Témy",
			path: "/temy",
			subMenu: map(
				themes.filter(
					(theme) => !theme.hasParentTheme && theme.displayInMenu
				),
				(theme) => ({
					key: theme.id,
					icon: theme.icon,
					title: theme.title,
					path: `/temy/${theme.urlSlug}`,
				})
			),
		},
		{
			key: "BLOG",
			icon: "",
			title: "Blog",
			path: "/blog",
		},
		// {
		// 	key: "MAP",
		// 	icon: "",
		// 	title: "Zelená mapa",
		// 	path: "/mapa",
		// },
		{
			key: "CONTACT",
			icon: "",
			title: "Kontakt",
			path: "/kontakt",
		},
	],
	linkComponent: Link,
	socialNetworksHeading: "Sledujte nas",

	menuButton: {
		title: "Zapojiť sa",
		color: "black",
		background: "#ECFAEF",
	},
})

const PageLayout = ({
	children,
	showMenu,
	hideNewsletter,
	menuType = "white",
}: Props) => {
	const selectedItem = useSelector(getSelectedMenu)
	const router = useRouter()
	const themes = useSelector(getThemesSelect)

	const [t] = useTranslation()

	const contactFormFields = {
		name: {
			label: t("components|ContactForm.fields.name.label"),
			placeholder: t("components|ContactForm.fields.name.placeholder"),
		},
		email: {
			label: t("components|ContactForm.fields.email.label"),
			placeholder: t("components|ContactForm.fields.email.placeholder"),
		},
		phone: {
			label: t("components|ContactForm.fields.phone.label"),
			placeholder: t("components|ContactForm.fields.phone.placeholder"),
		},
		message: {
			label: t("components|ContactForm.fields.message.label"),
			placeholder: t("components|ContactForm.fields.message.placeholder"),
		},
		consent: {
			label: t("components|ContactForm.fields.consent.label", {
				interpolation: { escapeValue: false },
			}),
		},
	}

	const handleSubmitContactForm = async (values: ContactFormValues) => {
		const data = {
			type: FORM_TYPE.CONTACT,
			recaptcha: values.recaptcha,
			form: {
				...values,
				recaptcha: undefined,
			},
		}
		console.log("handleSubmitContactForm", data.recaptcha)
		await form.sendForm(data)

		notification.success({
			key: "updatable",
			message: t("components|ContactForm.success"),
		})
	}

	const handleSubmitNewsletter = async (values: NewsletterFormValues) => {
		await form.newsletterSubscribe(values)

		notification.success({
			key: "updatable",
			message: t("components|Newsletter.success"),
		})
	}

	const layoutProps = args(themes.data?.themes || [])

	console.log("PageLayout")
	console.log(process.env.NEXT_PUBLIC_RECAPTCHA)

	return (
		<TreesLayout
			{...layoutProps}
			menuType={menuType}
			selectedItem={selectedItem}
			showMenu={showMenu}
			menuButton={{
				...layoutProps.menuButton,
				callback: () => router.push("/ako-sa-zapojit"),
			}}
		>
			{children}

			{!hideNewsletter && (
				<>
					<Container className={"wide"}>
						<ContactForm
							image={ContactFormImage}
							errorMessage={t("errors|message")}
							title={t("components|ContactForm.title")}
							submitTitle={t(
								"components|ContactForm.submitTitle"
							)}
							recaptchaText={t(
								"components|ContactForm.recaptchaText",
								{ interpolation: { escapeValue: false } }
							)}
							fields={contactFormFields}
							onSubmit={handleSubmitContactForm}
							sitekey={process.env.NEXT_PUBLIC_RECAPTCHA || "key"}
						/>
					</Container>

					<Container>
						<Newsletter
							linkComponent={Link}
							socialNetworksTitle={t(
								"components|Newsletter.socialNetworksTitle"
							)}
							newsletterTitle={t(
								"components|Newsletter.newsletterTitle"
							)}
							newsletterSubtitle={t(
								"components|Newsletter.newsletterSubtitle"
							)}
							socialsImage={SocialsImage}
							newsletterImage={NewsletterImage}
							onSubmitNewsletter={handleSubmitNewsletter}
						/>
					</Container>
				</>
			)}

			<link
				rel={"stylesheet"}
				href={"https://use.typekit.net/odf4xdx.css"}
			/>
			<style global={true} jsx>
				{`
					body {
						opacity: 0;
						transition: opacity 200ms;
					}
				`}
			</style>
		</TreesLayout>
	)
}

export default PageLayout
