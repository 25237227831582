export enum FORM {
	CONTACT = 'FORMS/CONTACT',
	NEWSLETTER = 'FORMS/NEWSLETTER',
	GREENERY_REQUEST = 'FORMS/GREENERY_REQUEST',
	CONTACT_PAGE = 'FORMS/CONTACT_PAGE'
}

export enum FORM_TYPE {
	CONTACT = 'CONTACT',
	NEWSLETTER = 'NEWSLETTER',
	GREENERY_REQUEST = 'GREENERY_REQUEST',
	DYNAMIC = 'DYNAMIC',
	WHERE_TO_PLANT = 'WHERE_TO_PLANT'
}

export enum FILTERS {
	MAP = 'FILTERS/MAP'
}

export enum GARDEN_TYPES {
	'FRONT_GARDEN_REQUEST' = 'FRONT_GARDEN_REQUEST',
	'COMMUNITY_GARDEN_REQUEST' = 'COMMUNITY_GARDEN_REQUEST',
}
