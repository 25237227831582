enum MENU_ITEM {
	ABOUT = 'ABOUT',
	INFO = 'INFO',
	TOPICS = 'TOPICS',
	BLOG = 'BLOG',
	MAP = 'MAP',
	CONTACT = 'CONTACT'
}

export const MENU_ITEMS = Object.values(MENU_ITEM)

export default MENU_ITEM
